import "@fontsource/lato"
import { Router } from "@reach/router"
import * as React from "react"
import ApiComponent from "../../../../components/ApisComponent"
import Layout from "../../../../components/Layout"
import "../../../../styles/common.css"

const ApiPage = () => {
  return (
    <Layout>
      <Router basepath="/references/platform-apis/api">
        <ApiComponent path="/" />
      </Router>
    </Layout>
  )
}

export default ApiPage
